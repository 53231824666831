import React from 'react';
import styled from '@emotion/styled';

import Button from '@sentry/static-shared/components/Button';
import { mqMin } from '@sentry/static-shared/utils/css';
import syntaxLogo from '@sentry/static-shared/images/syntax-logo-inline.png';
import { ltPink, richBlack, white } from '../../utils/css/colors';
import Link from '../link';

const syntaxBannerData = {
  title: "Listen to the Syntax Podcast",
  content: "Of course we sponsor a developer podcast. Check it out on your favorite listening platform.",
  linkLabel: "Listen To Syntax",
  linkTo: "https://syntax.fm/"
}

const SyntaxBanner = () => (
  <StyledSyntaxBanner>
    <StyledBannerWrapper>
      <Link target={'_blank'} to={syntaxBannerData.linkTo}>
        <img src={syntaxLogo} width={293} height={81.94}/>
      </Link>
      <StyledContentWrapper>
        <h3>{syntaxBannerData.title}</h3>
        <p>{syntaxBannerData.content}</p>
        <StyledButton to={syntaxBannerData.linkTo} variant='silent' compensate='left' redesign>{syntaxBannerData.linkLabel}</StyledButton>
      </StyledContentWrapper>
    </StyledBannerWrapper>
  </StyledSyntaxBanner>
);

const StyledSyntaxBanner = styled.div`
  background: ${richBlack};
  padding: 4.5rem;
`;

const StyledBannerWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 6.5rem;
    justify-content: center;
    margin: 0 auto;
    max-width: 43rem;

    ${mqMin('md')} {
        flex-direction: row;
    }
`;

const StyledContentWrapper = styled.div`
    color: ${white};
    flex: 0 1 auto;

    h3 {
        font-size: 1.25rem;
    }

    p {
        line-height: 1.5rem;
    }
`;

const StyledButton = styled(Button)`
    &.btn-new.silent {
        color: ${ltPink};
    }
`;

export default SyntaxBanner;