export const canonicalURL = (site, slug) => {
    let siteUrl = null;
    switch(site) {
        case 'www': 
            siteUrl = 'https://sentry.io';
            break;
        case 'blog':
            siteUrl = 'https://blog.sentry.io';
            break;
        case 'open':
            siteUrl = 'https://open.sentry.io';
            break;
        case 'help':
            siteUrl = 'https://help.sentry.io';
            break;
    }

    let canonical = siteUrl ? `${siteUrl}${slug}` : '';
    
    return canonical;
}