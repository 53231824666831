import React from 'react';
import excerptFromNode from '@sentry/static-shared/utils/excerpt-from-node';
import defaultMetaImage from '../../assets/meta/default.png';
import BlogPostCardNew from '../molecules/cards/BlogPostCard';
import FlexGrid, { FlexGridCell } from '@sentry/static-shared/components/FlexGrid';
import Layer from '@sentry/static-shared/components/layer-next';
import { richBlack, white } from '@sentry/static-shared/utils/css/colors';
import styled from '@emotion/styled';
import NumericPaginator from './NumericPaginator';
import { mqMin } from '@sentry/static-shared/utils/css';
import SyntaxBanner from '@sentry/static-shared/components/molecules/SyntaxBanner';
import Button from '@sentry/static-shared/components/Button';


const tabData = {
  tabs: [
    {
      link: 'https://sentry.engineering',
      text: 'engineering blog'
    },
    {
      link: 'https://sentry.io/changelog',
      text: 'changelog'
    },
    {
      link: 'https://sentry.io/customers',
      text: 'customer stories'
    },
    {
      link: 'https://syntax.fm',
      text: 'syntax episodes'
    },
    {
      link: 'https://about.codecov.io/resources',
      text: 'codecov'
    }
  ],
  tabHeader: 'More Sentry content'
};


const BlogPostGrid = ({ posts, pageContext, blogData }) => {

  const { tabs, tabHeader } = tabData;

  const formatBlog = (post) => ({
    ...post,
    excerpt: excerptFromNode(post),
    metaImage: post?.metaImage?.file?.url || defaultMetaImage,
    cta: {
      link: post?.fields?.url,
      text: 'Read on'
    },
    tag: post?.category?.title,
    date: post?.publishDate,
    defaultAvatars
  });

  const isHomePage = pageContext?.first;
  const featuredImageHeight = 243;
  const defaultImageHeight = 163;
  const offset = isHomePage ? 0 : 1;
  const defaultAvatars = blogData.dataModules.find(
    x => x.slug === 'fallback-avatars'
  ).assets;

  posts = posts.map(formatBlog);

  const firstBlogs = posts
    ?.slice(0, 2 + offset)
    ?.map(post =>
      ({
        ...post,
        imageHeight: (isHomePage ? featuredImageHeight : defaultImageHeight),
        isFeatured: isHomePage,
        breakpoint: (isHomePage ? 6 : 4)
      })
    );

  const nextBlogs = posts
    ?.slice(2 + offset, 5 + offset)
    ?.map(post =>
      ({
        ...post,
        imageHeight: defaultImageHeight
      })
    );

  const remainingBlogs = posts
    ?.slice(5 + offset, 14 + offset)
    ?.map(post =>
      ({
        ...post,
        imageHeight: defaultImageHeight
      })
    );

  return (
    <StyledLayer
      lightType
      backgroundColor={richBlack}
    >
      <StyledFlexGrid>
        {firstBlogs.map(post => (
          <FlexGridCell md={post?.breakpoint}>
            <BlogPostCardNew {...post} />
          </FlexGridCell>
        ))}
      </StyledFlexGrid>
      <StyledFlexGrid>
        {nextBlogs.map(post => (
          <FlexGridCell md={4}>
            <BlogPostCardNew {...post} />
          </FlexGridCell>
        ))}
      </StyledFlexGrid>
      <StyledFlexGrid>
        <FlexGridCell>
          <TabsWrapper>
            <h3>{tabHeader}</h3>
            {tabs && tabs.map(({ link, text }) => (
              <Button
                to={link}
                redesign
                variant="secondary-dark"
                target="_blank"
              >
                {text}
              </Button>
            ))}
          </TabsWrapper>
        </FlexGridCell>
      </StyledFlexGrid>
      <StyledFlexGrid>
        {remainingBlogs.map(post => (
          <FlexGridCell md={4}>
            <BlogPostCardNew {...post} />
          </FlexGridCell>
        ))}
      </StyledFlexGrid>
      <CenteredFlexGrid>
        <NumericPaginator pageContext={pageContext}/>
      </CenteredFlexGrid>
      <SyntaxBanner/>
    </StyledLayer>
  );
};

const StyledLayer = styled(Layer)`
  div.container {
    padding-top: 0;
  }
`;

const StyledFlexGrid = styled(FlexGrid)`
  > div {
    padding: 1.5rem 1rem;
  }
`;

const CenteredFlexGrid = styled(FlexGrid)`
  display: flex;
  justify-content: center;
  margin: 1.5rem auto;
`;


const TabsWrapper = styled.div`
  ${mqMin('lg')} {
    margin-bottom: 3.75rem;
    margin-top: 3.75rem;
    flex-wrap: nowrap;
  }
  flex-wrap: wrap;
  display: flex;
  gap: 1rem;
  h3 {
    width: 100%;
    ${mqMin('lg')}{
      width: auto;
    }
  }
  a {
    height: 2rem;
    padding: 0.5rem 0.75rem;
    font-size: 13px;
    width: auto;
    span {
      line-height: 1;
    }
  }
`;

export default BlogPostGrid;