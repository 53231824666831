import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import BlogPostGrid from '../components/organisms/BlogPostGrid';
import { graphql } from 'gatsby';
import HeroLayer from '../components/organisms/HeroLayer';
import { useLocation } from '@reach/router';
import { canonicalURL } from '@sentry/static-shared/utils/CanonicalLink';

const IndexPage = ({
   data: {
     allContentfulBlogPost,
     allContentfulBlogCategory,
     contentfulBlogGeneralSettings,
     contentfulPage: blogData,
     site: {
       siteMetadata: { siteUrl },
     },
   },
   pageContext
 }) => {
  const posts = allContentfulBlogPost.edges.map(x => x.node);
  const location = useLocation();
  const canonicalLink = canonicalURL('www', location.pathname);
  const {
    blogHomepageTitle,
    blogHomepageMetaImage,
    blogHomepageMetaDescription
  } = contentfulBlogGeneralSettings;

  return (
    <Layout
      mode="dark"
    >
      <SEO
        title={blogHomepageTitle}
        description={blogHomepageMetaDescription}
        image={blogHomepageMetaImage.gatsbyImageData}
        canonical={canonicalLink}
      />
      <HeroLayer title={blogHomepageTitle} />
      <BlogPostGrid {...{ posts, pageContext, blogData }} />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  fragment Image on ContentfulAsset {
    title
    description
    file {
      url
      contentType
      details {
        image {
          width
          height
        }
      }
    }
  }
  

  query blogListQuery($posts: [String!]) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    # Global Blog Data
    contentfulPage(contentful_id: { eq: "6PaNQpeuz5DLUwC3KP09R5" }) {
      dataModules {
        slug
        assets {
          ...Image
          gatsbyImageData(width: 32)
        }
      }
    }
    allContentfulBlogCategory(sort: { fields: [title], order: ASC }) {
      edges {
        node {
          title
          slug
        }
      }
    }

    allContentfulBlogPost(
      filter: { slug: { in: $posts } }
      sort: { fields: [publishDate], order: DESC }
    ) {
      edges {
        node {
          ...BlogPost
        }
      }
    }
    contentfulBlogGeneralSettings {
      blogHomepageTitle
      blogHomepageMetaDescription
      blogHomepageMetaImage {
        ...Image
      }
    }
  }
`;