import React from 'react';
import styled from '@emotion/styled';
import Button from '@sentry/static-shared/components/Button';
import { mqMin } from '@sentry/static-shared/utils/css';

const NumericPaginator = ({ pageContext }) => {
  const { pagination, first, last, pathPrefix, firstPath } = pageContext;
  const onlyPage = first && last;
  const { currentPage, totalPages } = pagination;

  const buildButtons = () => {
    const buttons = [];
    let totalButtons = 3;
    let startPage = currentPage - 1;

    if (currentPage <= 4) {
      startPage = 1
      totalButtons = 5
    } else if (currentPage >= totalPages - 3) {
      startPage = totalPages - 4
      totalButtons = 5
    }

    for (let i = 0; i < totalButtons; i++) {
      const page = startPage + i;
      buttons.push({
        text: page,
        link: page !== 1 ? `/${pathPrefix}/${page}/` : '/',
        active: currentPage === page
      });
    }

    if (totalPages - currentPage > 3) {
      buttons.push({ text: '...', variant: 'silent' });
      buttons.push({ text: totalPages, link: `/${pathPrefix}/${totalPages}/` });
    }

    if (startPage > 3) {
      buttons.splice(0, 0,{ text: '...', variant: 'silent' });
      buttons.splice(0, 0, { text: 1, link: '/' });
    }

    return buttons;
  };


  if (onlyPage) {
    return (
      <></>
    )
  }

  return (
    <Wrapper>
      {
        buildButtons().map(({ link, variant, text, active }) => (
          <Button
            to={link}
            redesign
            variant={variant || 'secondary-dark'}
            disabled={active}
          >
            {text}
          </Button>
        ))
      }
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  ${mqMin('md')}{
    gap: 1rem;
  }
  gap: .25rem;
`;

export default NumericPaginator;